<template>
<div class="page-diferencias-ventas">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Diferencias en Facturación" class="elevation-1 px-5 py-3">

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-row>
              <v-col sm="2" md="2" lg="2">
              </v-col>
              <v-col sm="3" md="3" lg="3" style="text-align:right !important">
                <br>
                DIFERENCIAS DE FACTURACION AL DÍA: 
              </v-col>
              <v-col sm="3" md="3" lg="3">
                  <v-menu offset-x ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                    <template v-slot:activator="{ on }">
                        <v-text-field v-on="on" v-model="model.fecha_de" label="Fecha" color="secondary" prepend-icon="event"
                        @blur="model.fecha_de = parse_date(model.fecha_de)" :rules="[rules.required, valida_fecha]">
                        </v-text-field>
                    </template>

                    <v-date-picker v-model="model.fecha_de" color="secondary" scrollable locale="es-mx" @input="menu1 = false"
                      :min="minDate"
                      :max="maxDate"
                      @change="set_mes()"
                    >
                        <v-spacer />

                        <v-btn color="secondary" x-small @click="menu1 = false">
                        Cancelar
                        </v-btn>
                    </v-date-picker>
                    </v-menu>
              </v-col>              
              <v-col sm="3" md="3" lg="3">
                <v-btn color="success" @click.native="consultar()">
                  <v-icon>done</v-icon> {{ "Generar Reporte" }}
                </v-btn>
              </v-col>
              <v-col sm="2" md="2" lg="2">
              </v-col>
            </v-row>
            <br>
            <hr>

            <div id="reporte_excel">
                    <meta charset="utf-8">

                    <v-row>
                      <v-col sm="7" md="7" lg="7">
                        <table width="100%">
                          <tr>
                            <td width="60%">&nbsp;</td>
                            <td width="20%">&nbsp;</td>
                            <td width="20%" style="text-align:center"><strong>EGRESOS</strong></td>                                                                          
                          </tr>
                          <tr>
                            <td><strong>VENTAS TOTALES</strong></td>
                            <td style="text-align:right"><strong>${{formatNumberDec(ventas_totales,2)}}</strong></td>  
                            <td>&nbsp;</td>                            
                          </tr>
                          <tr>
                            <td>- FACTURAS A CLIENTES</td>
                            <td style="text-align:right">${{formatNumberDec(facturas_cliente,2)}}</td>                        
                            <td>&nbsp;</td>                                               
                          </tr>
                          <tr>
                            <td>- FACTURA GLOBAL</td>
                            <td style="text-align:right">${{formatNumberDec(facturas_global,2)}}</td>                        
                            <td>&nbsp;</td>                            
                          </tr>
                          <tr v-show="sin_facturar_del_dia>0">
                            <td>- SIN FACTURAR DEL DÍA</td>
                            <td style="text-align:right">${{formatNumberDec(sin_facturar_del_dia,2)}}</td>
                            <td>&nbsp;</td>                            
                          </tr>
                          <tr>
                            <td>- VENTAS DE HOY FACTURADAS EN OTROS DÍAS</td>
                            <td style="text-align:right">${{formatNumberDec(ventas_hoy_facturada_otro_dia,2)}}</td>
                            <td>&nbsp;</td>                            
                          </tr>
                          <tr>
                            <td>- VENTAS DE HOY FACTURADAS EN OTROS DÍAS EN GLOBAL</td>
                            <td style="text-align:right">${{formatNumberDec(ventas_hoy_facturada_otro_dia_global,2)}}</td>
                            <td style="text-align:right" class="rojo">${{formatNumberDec(ventas_hoy_facturada_otro_dia_global_con_nota,2)}}</td>                            
                          </tr>
                          <tr>
                            <td style="border-top: 1px solid black;" class="rojo"><strong>DIFERENCIA DEL DÍA</strong></td>
                            <td style="border-top: 1px solid black;text-align:right;" class="rojo"><strong>${{formatNumberDec(diferencia_dia,2)}}</strong></td>
                            <td style="border-top: 1px solid black;">&nbsp;</td>                            
                          </tr>
                        </table><br>
                        <table width="100%">                      
                          <tr>
                            <td width="60%"><span title="VENTAS DE DIAS DIFERENTES AL DE LA CONSULTA DONDE LA FECHA DE LA FACTURA SEA IGUAL A LA FECHA CONSULTADA Y QUE NO ESTAN EN SU GLOBAL">
                              FACTURAS DE VENTAS DE OTROS DIAS</span></td>
                            <td width="20%" style="text-align:right">${{formatNumberDec(facturas_otros_dias_no_global,2)}}</td>                        
                            <td width="20%">&nbsp;</td>                            
                          </tr>
                          <tr>
                            <td><span title="VENTAS DE DIAS DIFERENTES AL DE LA CONSULTA DONDE LA FECHA DE LA FACTURA SEA IGUAL A LA FECHA CONSULTADA Y QUE SI ESTAN EN SU GLOBAL">
                              FACTURAS DE VENTAS DE OTROS DÍAS EN GLOBAL</span></td>
                            <td style="text-align:right">${{formatNumberDec(facturas_otros_dias,2)}}</td>
                            <td style="text-align:right" class="rojo">${{formatNumberDec(facturas_otros_dias_egresos,2)}}</td>                            
                          </tr>
                          <tr>
                            <td style="border-top: 1px solid black;"><strong>FACTURACIÓN DEL DÍA</strong></td>
                            <td style="border-top: 1px solid black;text-align:right"><strong>${{formatNumberDec(facturacion_del_dia,2)}}</strong></td>
                            <td style="border-top: 1px solid black;">&nbsp;</td>                            
                          </tr>                      
                        </table>
                      </v-col>
                      <v-col sm="1" md="1" lg="1">
                        &nbsp;
                      </v-col>
                      <v-col sm="4" md="4" lg="4">
                        
                        <strong></strong><br>
                        <table width="100%">                      
                          <tr>
                            <th width="40%">ACUMULADO AL DÍA {{model.fecha_de}}</th>
                            <th width="30%" style="text-align:right">Bodeguita</th>
                            <th width="30%" style="text-align:right">Power CFDI</th>
                          </tr>
                          <tr>
                            <td>VENTAS TOTALES</td>
                            <td style="text-align:right;">${{formatNumberDec(ventas_totales_mes,2)}}</td>
                            <td style="text-align:right;">&nbsp;</td>
                          </tr>
                          <tr>
                            <td>FACTURAS INGRESOS</td>
                            <td style="text-align:right;">${{formatNumberDec(facturadas_mes,2)}}</td>
                            <td style="text-align:right;">${{formatNumberDec(facturadas_mes_power,2)}}</td>
                          </tr>
                          <tr>
                            <td>FACTURAS EGRESOS</td>
                            <td style="text-align:right;">${{formatNumberDec(egresos_mes,2)}}</td>
                            <td style="text-align:right;">${{formatNumberDec(egresos_mes_power,2)}}</td>
                          </tr>
                          <tr>
                            <td style="border-top: 1px solid black;" class="rojo"><strong>DIFERENCIA</strong></td>
                            <td style="border-top: 1px solid black;text-align:right;" class="rojo"><strong>${{formatNumberDec(diferencia_mes,2)}}</strong></td>
                            <td style="border-top: 1px solid black;text-align:right;" class="rojo"><strong>${{formatNumberDec((ventas_totales_mes-facturadas_mes_power+egresos_mes_power),2)}}</strong></td>
                          </tr>
                        </table>

                      </v-col>
                    </v-row>
                    <br>

                    <br>

                    <h5>DETALLE DE MOVIMIENTOS DEL MES: {{mes_seleccionado}}  AÑO: {{anio_seleccionado}}</h5><br>
                    <strong>VENTAS SIN FACTURAR</strong>
                    <table width="52%" border="1">    
                      <thead>
                        <tr>
                          <th width="50%" style="text-align:center;">NO. VENTA</th>
                          <th width="25%" style="text-align:center;">FECHA VENTA</th>
                          <th width="25%" style="text-align:center;">IMPORTE</th>                        
                        </tr>
                      </thead>
                      <tr v-for="(venta, index) in ventas_sin_facturar" >
                        <td style="text-align:center;"><span>{{venta.ticket}}</span></td>
                        <td style="text-align:center;"><span>{{parse_date(venta.fecha)}}</span></td>
                        <td style="text-align:right">${{formatNumberDec(venta.total,2)}}</td>                        
                      </tr>                      
                    </table><br>

                    <strong>
                      <span title="Ventas que estaban el la Factura Global y que después facturó el cliente y que debería tener su nota de crédito">
                        VENTAS FACTURADAS SIN NOTA DE CRÉDITO</span></strong>
                    <table width="100%" border="1">    
                      <thead>
                        <tr>
                          <th width="20%" style="text-align:center;">NO. VENTA</th>
                          <th width="10%" style="text-align:center;">FECHA VENTA</th>
                          <th width="10%" style="text-align:center;">IMPORTE</th>
                          <th width="20%" style="text-align:center;">UUID</th>
                          <th width="10%" style="text-align:center;">FECHA FACTURA</th> 
                          <th width="10%" style="text-align:center;">Opciones</th>                          
                        </tr>
                      </thead>
                      <tr v-for="(venta, index) in facturadas_sin_nota_credito" >
                        <td style="text-align:center;"><span>{{venta.ticket}}</span></td>
                        <td style="text-align:center;"><span>{{parse_date(venta.fecha)}}</span></td>
                        <td style="text-align:right">${{formatNumberDec(venta.total,2)}}</td>
                        <td style="text-align:center;"><span>{{venta.id_facturacion}}</span></td>
                        <td style="text-align:center;"><span>{{parse_date(venta.fecha_timbrado)}}</span></td> 
                        <td style="text-align:center;">
                          <v-btn x-small fab dark color="orange" 
                            @click.native="crear_nota(venta.id_facturacion, venta._id, venta.ticket)" title="Crear Nota de Crédito" v-tippy>
                            <v-icon>note</v-icon>
                          </v-btn>
                        </td>                        
                      </tr>                      
                    </table><br>

                    <strong>
                      <span title="">
                        VENTAS EN FACTURA GLOBAL CON NOTA DE CRÉDITO</span></strong>
                    <table width="90%" border="1">    
                      <thead>
                        <tr>
                          <th width="20%" style="text-align:center;">NO. VENTA</th>
                          <th width="10%" style="text-align:center;">FECHA VENTA</th>
                          <th width="10%" style="text-align:center;">IMPORTE</th>
                          <th width="20%" style="text-align:center;">UUID</th>
                          <th width="10%" style="text-align:center;">FECHA FACTURA</th>                          
                        </tr>
                      </thead>
                      <tr v-for="(venta, index) in global_y_nota_credito" >
                        <td style="text-align:center;"><span>{{venta.ticket}}</span></td>
                        <td style="text-align:center;"><span>{{parse_date(venta.fecha)}}</span></td>
                        <td style="text-align:right">${{formatNumberDec(venta.total,2)}}</td>
                        <td style="text-align:center;"><span>{{venta.id_facturacion}}</span></td>
                        <td style="text-align:center;"><span>{{parse_date(venta.fecha_timbrado)}}</span></td>                        
                      </tr>                      
                    </table><br>

                    <strong>
                      <span title="Ventas que están el la Factura Global y también facturada al cliente">
                        VENTAS EN FACTURA GLOBAL Y FACTURADA AL CLIENTE</span></strong>
                    <table width="90%" border="1">    
                      <thead>
                        <tr>
                          <th width="20%" style="text-align:center;">NO. VENTA</th>
                          <th width="10%" style="text-align:center;">FECHA VENTA</th>
                          <th width="10%" style="text-align:center;">IMPORTE</th>
                          <th width="20%" style="text-align:center;">UUID</th>
                          <th width="10%" style="text-align:center;">FECHA FACTURA</th>                          
                        </tr>
                      </thead>
                      <tr v-for="(venta, index) in global_y_cliente" >
                        <td style="text-align:center;"><span>{{venta.ticket}}</span></td>
                        <td style="text-align:center;"><span>{{parse_date(venta.fecha)}}</span></td>
                        <td style="text-align:right">${{formatNumberDec(venta.total,2)}}</td>
                        <td style="text-align:center;"><span>{{venta.id_facturacion}}</span></td>
                        <td style="text-align:center;"><span>{{parse_date(venta.fecha_timbrado)}}</span></td>                        
                      </tr>                      
                    </table><br>


                    <strong>
                      <span title="">
                        VENTAS CON FACTURA EN PROCESO DE CANCELACIÓN</span></strong>
                    <table width="90%" border="1">    
                      <thead>
                        <tr>
                          <th width="20%" style="text-align:center;">NO. VENTA</th>
                          <th width="10%" style="text-align:center;">FECHA VENTA</th>
                          <th width="10%" style="text-align:center;">IMPORTE</th>
                          <th width="20%" style="text-align:center;">UUID</th>
                          <th width="10%" style="text-align:center;">FECHA FACTURA</th>                          
                        </tr>
                      </thead>
                      <tr v-for="(venta, index) in facturas_proceso_cancelacion" >
                        <td style="text-align:center;"><span>{{venta.ticket}}</span></td>
                        <td style="text-align:center;"><span>{{parse_date(venta.fecha)}}</span></td>
                        <td style="text-align:right">${{formatNumberDec(venta.total,2)}}</td>
                        <td style="text-align:center;"><span>{{venta.id_facturacion}}</span></td>
                        <td style="text-align:center;"><span>{{parse_date(venta.fecha_timbrado)}}</span></td>                        
                      </tr>                      
                    </table><br>

                    <strong>
                      <span title="Facturas que tienen el mismo No de Venta y el mismo importe">
                        FACTURAS DUPLICADAS EN EL MES</span></strong>
                    <table width="90%" border="1">    
                      <thead>
                        <tr>
                          <th width="20%" style="text-align:center;">NO. VENTA</th>
                          <th width="10%" style="text-align:center;">FECHA VENTA</th>
                          <th width="10%" style="text-align:center;">IMPORTE</th>
                          <th width="20%" style="text-align:center;">UUID</th>    
                          <th width="10%" style="text-align:center;">FECHA FACTURA</th>                        
                        </tr>
                      </thead>
                      <tr v-for="(venta, index) in facturas_duplicadas_mes" >
                        <td style="text-align:center;"><span>{{venta.ticket}}</span></td>
                        <td style="text-align:center;"><span>{{parse_date(venta.fecha_venta)}}</span></td>
                        <td style="text-align:right">${{formatNumberDec(venta.importe_venta,2)}}</td>
                        <td style="text-align:center;"><span>{{venta.uuid}}</span></td>
                        <td style="text-align:center;"><span>{{parse_date(venta.fecha)}}</span></td>     
                      </tr>                      
                    </table><br>

                    <!--table>
                      
                      <tr v-for="(venta, index) in facturadas_mes_detalle" >
                        <td style="text-align:center;"><span>{{venta.ticket}}</span></td>
                        <td style="text-align:center;"><span>{{venta.id_facturacion}}</span></td>
                        <td style="text-align:right">{{formatNumberDec(venta.total,2)}}</td>
                        <td style="text-align:center;"><span>{{venta.tipo}}</span></td>                        
                      </tr>                      
                    </table-->
            </div>

          </v-container>
        </v-form>
      </v-card-text>
      
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

</div>
</template>

<script>

export default {
  components: {
  },
  watch: {
    model_filters: {
      handler(val) {
        this.$nextTick(() => {
          this.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        });
      },
      deep: true
    },
    
  },
  mounted: function() {
    if (!this.verificaPermiso('t.ventas.totales')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.model = this.clean_model();
    
    var today = window.moment().format("YYYY-MM-DD");
    this.maxDate = window.moment().subtract(1, 'days').format("YYYY-MM-DD");    
    this.model.fecha_de = window.moment().subtract(1, 'days').format("YYYY-MM-DD");
    this.set_mes();        
  },
  data() {
    return {
        id_solicitud_egreso:null,
        model_egreso:{},
        db_config: {
          headers: {
            Authorization: "Basic YWRtaW46YWRtaW4xMjMq"
          }
        },
        factura_global:null,
        minDate: "2024-01-01",
        maxDate: null,
        mes_seleccionado:0,
        anio_seleccionado:0,
        ventas_totales:0, //Sumatoria de las ventas en estatus Finalizada, Facturada, Factura Global
        ventas_totales_mes:0, //Sumatoria de las ventas en estatus Finalizada, Facturada, Factura Global del mes seleccionado
        facturadas_mes:0,
        facturadas_mes_power:0,
        facturadas_mes_detalle:[],
        egresos_mes:0,
        egresos_mes_power:0,
        diferencia_mes:0,
        diferencia_mes_power:0,
        facturas_cliente:0, //Sumatoria de las ventas en estatus Facturada
        facturas_cliente_couch:0,
        ventas_hoy_facturada_otro_dia:0,//
        ventas_hoy_facturada_otro_dia_global:0,
        ventas_hoy_facturada_otro_dia_global_con_nota:0,
        facturas_global:0, //Sumatoria de las ventas en estatus Factura Global
        diferencia_dia:0, //Diferencia de ventas totales - facturadas - facturadas global
        sin_facturar_del_dia:0,

        facturas_otros_dias:0, //Ventas de otros días que estaban en la global, que se facturaron el dia seleccionado y por lo tanto deben tener nota de crédito
        facturas_otros_dias_egresos:0,
        facturas_otros_dias_no_global:0, //Facturas de otros dias, que no estaban en las globales
        facturacion_del_dia:0,
        notas_credito:0, //Sumatoria del importe de las notas de crédito del dia seleccionado
        diferencia_otros_dias:0,

        ventas_sin_facturar:[], //Ventas en estatus Finalizada        
        facturadas_sin_nota_credito:[], 
        ids_ventas_globales:[],
        tickets_globales:[],
        tickets_globales_dia:{},
        global_y_nota_credito:[],
        global_y_cliente: [],
        facturas_proceso_cancelacion:[], //Son las ventas que tienen el estatus_cancelado= En Proceso
        
        facturas_duplicadas_mes:[], //Ventas del mes que se facturaron 2 veces
        api: 'https://ozu2hub36m.execute-api.us-east-1.amazonaws.com/dev/',        

        sucursales:[],
        menu1:false,
        menu2:false,
        menu3:false,
        menu4:false,
      search_articulo:'',
      search_marca: null,
      tipos_precios: [],
      categorias:[],
      marcas:[],
      articulos:[],
      articulos_proveedores:[],
      fab: [],
      isLoading: false,
      articulos_encontrados: [],
      id_articulo: "",

      search: "",
      model_filters: "",

      tmp_fecha: "",
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Reportes",
          disabled: true,
          href: ""
        },
        {
          text: "Diferencias en Facturación",
          disabled: true,
          href: ""
        }
      ],

      modal: false,
      modal_articulos: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no valido";
        },
        rfc: v =>
          (v && v.length >= 9 && v.length < 15) || "RFC debe tener al menos 9 caractéres y 14 caractéres"
      },
      items: []
    }
  },
  methods: {
    set_mes: function(){
      this.mes_seleccionado = this.parse_month(this.model.fecha_de);
      this.anio_seleccionado = this.parse_year(this.model.fecha_de);
    },
    get_sucursales: function () {
        let data = {
            "selector": {
                "type":"sucursales",
                "estatus": {
                    "$eq": "Activo"
                }
            },
            "fields": [
                "nombre", "_id"
            ]
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                this.sucursales = [];
                if (response.data.docs.length > 0)
                    this.sucursales = response.data.docs;
                    let todas = {
                        _id: '0',
                        nombre: 'TODAS'
                    };
                    this.sucursales.unshift(todas);
            })

            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las sucursales.",
                    footer: ""
                });
            });
    },
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    parse_month(date) {
      if (!date) return null;
      return parseInt(moment(String(date)).format("MM"));
    },
    parse_year(date) {
      if (!date) return null;
      return parseInt(moment(String(date)).format("YYYY"));
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    valida_time: function(time) {
      if(time!=null && time.toString()!=""){
            if(time.toString().length != 5)
                return "Hora no valida1";
            if(!time.match(/^\d{2}:(?:[0-5]\d)$/))
                return "Hora no valida2";
            var time_arr = time.split(":");
            if(time_arr.length!=2){
                return "Hora no valida3";
            }else{
                if(isNaN(time_arr[0]) || isNaN(time_arr[1])){
                    return "Hora no valida4";
                }
                if(parseInt(time_arr[0])<24 && parseInt(time_arr[1])<60)
                {
                    return true;
                } else{
                    return "Hora no valida5";
                }
            }
      }
      return true;

    },
    clean_model: function() {
      return {
        fecha_de: '',        
      };
    },
    consultar: function(){
       window.dialogLoader.show('Espere un momento por favor..');

        //Del dia seleccionado
        this.ventas_totales = 0; //Sumatoria de las ventas en estatus Finalizada, Facturada, Factura Global
        this.ventas_totales_mes = 0;
        this.diferencia_mes = 0;
        this.diferencia_mes_power = 0;
        this.facturadas_mes = 0;
        this.facturadas_mes_power = 0;
        this.facturadas_mes_detalle = [];
        this.egresos_mes = 0;
        this.egresos_mes_power = 0;
        this.facturas_cliente = 0; //Sumatoria de las ventas en estatus Facturada
        this.facturas_cliente_couch = 0;
        this.ventas_hoy_facturada_otro_dia = 0;
        this.ventas_hoy_facturada_otro_dia_global = 0;
        this.ventas_hoy_facturada_otro_dia_global_con_nota = 0;
        this.facturas_global = 0; //Sumatoria de las ventas en estatus Factura Global
        this.diferencia_dia = 0; //Diferencia de ventas totales - facturadas - facturadas global
        this.sin_facturar_del_dia = 0;

        this.facturas_otros_dias = 0; //Ventas de otros días, que se facturaron el dia seleccionado y por lo tanto deben tener nota de crédito
        this.facturas_otros_dias_egresos = 0;
        this.facturas_otros_dias_no_global = 0;
        this.facturacion_del_dia = 0;
        this.notas_credito = 0; //Sumatoria del importe de las notas de crédito del dia seleccionado
        this.diferencia_otros_dias = 0;

        //Del dia 1ro al dia seleccionado (del mes y año seleccionado)
        this.ventas_sin_facturar = []; //Ventas en estatus Finalizada        
        this.facturadas_sin_nota_credito = []; 
        this.ids_ventas_globales = [];
        this.tickets_globales = [];
        this.tickets_globales_dia = {};
        this.global_y_nota_credito = [];
        this.global_y_cliente = [];
        this.facturas_proceso_cancelacion = []; //Son las ventas que tienen el estatus_cancelado= En Proceso
          
        this.facturas_duplicadas_mes = [];

        var data = {
            "selector": {
                "type":"ventas",
                "fecha": {
                    "$gte": this.model.fecha_de,
                    "$lte": this.model.fecha_de+ "T23:59:59.999"
                },          
                //para cumplir con el indice            
                "sucursal._id":{"$exists":true},
                "usuario_id":{"$exists":true},
                "cliente.nombre":{"$exists":true},
                "cliente._id":{"$exists":true},
            },
            "use_index":"_design/7f056a8e777d8361294cecc9ad40b9a3299b127c",
            "sort": [{"fecha":"desc"}],
            "fields": ["_id",
                "ticket", "sucursal", "fecha","total","estatus",
                "id_facturacion","fecha_timbrado","id_facturacion_egreso","error_timbrado_egreso",
                "fecha_cancelado",
                "estatus_cancelado", //En Proceso|Cancelado
            ]
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(async response => {

                //FACTURAS GLOBALES
                var fecha_inicial_globales = moment(String(this.model.fecha_de)).format("YYYY")+"-"+moment(String(this.model.fecha_de)).format("MM")+"-01";
                let datos_global_dia = {
                    "selector":{
                      "type":"facturas_globales",
                      "id_facturacion":{"$exists":true},
                      "fecha": {
                          "$gte": fecha_inicial_globales,
                          "$lte": this.model.fecha_de
                      },                                                 
                    },
                    "use_index":"_design/053036a30a6dce87e13c7aa0623e453e2258a3ee",
                    "fields": ["_id", "estatus", "fecha", "total", "ids", "id_facturacion"]
                }; 
                
                await window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', datos_global_dia)
                .then(async responseGlobal => {
                  if(responseGlobal.data && responseGlobal.data.docs){
                      for(var k in responseGlobal.data.docs){
                        if(responseGlobal.data.docs[k]["estatus"] == "Facturado"){
                          //Las del día:
                          if(responseGlobal.data.docs[k]["fecha"] == this.model.fecha_de){
                            this.facturas_global += parseFloat(responseGlobal.data.docs[k]["total"]);
                          }                          
                          this.facturadas_mes += parseFloat(responseGlobal.data.docs[k]["total"]);
                          this.facturadas_mes_detalle.push({"ticket":"", "id_facturacion":responseGlobal.data.docs[k]["id_facturacion"],
                            "total":responseGlobal.data.docs[k]["total"], "tipo":"global"});
                          this.ids_ventas_globales = this.ids_ventas_globales.concat(responseGlobal.data.docs[k]["ids"]);                        
                        }                        
                      }
                        
                  }                      
                }).catch(error => {
                    console.log(error);
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al consultar la factura global del día seleccionado. Intente nuevamente.",
                        footer: ""
                    });
                });

                if (response.data.docs.length > 0){
                    this.registros = response.data.docs;
                                        

                    for(var key in this.registros){
                        var venta = this.registros[key];
                        if(venta["estatus"] == "Finalizada"){
                          this.ventas_totales += parseFloat(venta["total"]);  
                          this.sin_facturar_del_dia += parseFloat(venta["total"]);
                        }
                        if(venta["estatus"] == "Facturada"){
                          this.ventas_totales += parseFloat(venta["total"]);                                                    
                          if(venta["fecha_timbrado"] && venta["fecha"].substring(0,10) != venta["fecha_timbrado"].substring(0,10)){
                            if(this.ids_ventas_globales.includes(venta["_id"])){
                              this.ventas_hoy_facturada_otro_dia_global += parseFloat(venta["total"]);
                              if(venta["id_facturacion_egreso"] && venta["id_facturacion_egreso"]!=""){
                                this.ventas_hoy_facturada_otro_dia_global_con_nota += parseFloat(venta["total"]);
                              }
                            } else {
                              this.ventas_hoy_facturada_otro_dia += parseFloat(venta["total"]);
                            }
                            
                          }
                        }
                        if(venta["estatus"] == "Factura Global"){
                          this.ventas_totales += parseFloat(venta["total"]);                          
                        }                           
                    }

                    //FACTURADAS A CLIENTES, se obtienen de Power-CFDI
                    let facturadas_api = {
                        "rfc": "BGU960207S5A",
                        "fecha": this.model.fecha_de,                        
                    };
                    await axios.post(this.api + 'carta_porte/total_emisor_dia/', facturadas_api)                    
                    .then(async responseApi => { 
                      //console.log("API Facturadas: ", responseApi.data);
                      if(responseApi.data && responseApi.data.total){
                        if(responseApi.data.total){
                          this.facturas_cliente = parseFloat(responseApi.data.total);
                        }
                        if(responseApi.data.total_acumulado_ingresos){
                          this.facturadas_mes_power = parseFloat(responseApi.data.total_acumulado_ingresos);
                        }
                        if(responseApi.data.total_acumulado_egreso){
                          this.egresos_mes_power = parseFloat(responseApi.data.total_acumulado_egreso);
                        }
                      }
                      
                    }).catch(error => {
                        console.log(error);
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al consultar las ventas del día. Intente nuevamente.",
                            footer: ""
                        });
                    });                    

                    //OTROS DIAS: Facturas timbradas en el día seleccionado, pero que su fecha de venta es anterior
                    var data_otros = {
                      "selector": {
                          "type":"ventas",
                          "fecha_timbrado": {
                              "$gte": this.model.fecha_de,
                              "$lte": this.model.fecha_de+ "T23:59:59.999"
                          },                          
                      },
                      "use_index":"_design/2acea62bb1eacb7d80fc3102cbc18d798f3ab47b",
                      "fields": ["_id",
                          "ticket", "sucursal", "fecha", "total","estatus",
                          "id_facturacion","fecha_timbrado","id_facturacion_egreso","error_timbrado_egreso",
                          "fecha_cancelado",
                          "estatus_cancelado", //En Proceso|Cancelado
                      ]
                  };
                  await window.axios
                      .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data_otros)
                      .then(async responseOtros => {

                          if (responseOtros.data && responseOtros.data.docs){
                            for(var k in responseOtros.data.docs){
                              var venta = responseOtros.data.docs[k];
                              if(venta["estatus"] == "Facturada" && venta["fecha_timbrado"] && venta["fecha"].substring(0,10) == venta["fecha_timbrado"].substring(0,10)){
                                this.facturas_cliente_couch += parseFloat(venta["total"]); 
                              }
                              if(venta["estatus"] == "Facturada" && venta["fecha_timbrado"] && venta["fecha"].substring(0,10) != venta["fecha_timbrado"].substring(0,10)){
                                  if(this.ids_ventas_globales.includes(venta["_id"]) ){
                                    //Facturadas otros dias que estaban en la global, y deben tener nota de crédito
                                    this.facturas_otros_dias += parseFloat(venta["total"]);   
                                    if(venta["id_facturacion_egreso"] && venta["id_facturacion_egreso"]!=""){
                                        this.facturas_otros_dias_egresos += parseFloat(venta["total"]);
                                    }
                                  } else {
                                    //Facturadas otros días, que no estaban en la global
                                    this.facturas_otros_dias_no_global += parseFloat(venta["total"]);   
                                  }
                                
                              }
                            }
                          }
                      }).catch(error => {
                        console.log(error);
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al consultar facturas de otros días. Intente nuevamente.",
                            footer: ""
                        });
                    });

                    this.facturas_cliente = this.facturas_cliente - this.facturas_otros_dias - this.facturas_otros_dias_no_global;
                    this.diferencia_dia = this.ventas_totales - this.facturas_cliente - this.facturas_global - this.sin_facturar_del_dia - this.ventas_hoy_facturada_otro_dia - this.ventas_hoy_facturada_otro_dia_global + this.ventas_hoy_facturada_otro_dia_global_con_nota;
                    this.diferencia_otros_dias = this.facturas_otros_dias - this.facturas_otros_dias_egresos;
                    this.facturacion_del_dia = this.facturas_cliente + this.facturas_global + this.facturas_otros_dias + this.facturas_otros_dias_no_global;
                    

                } else {
                  this.registros = [];
                }
                
                var fecha_inicial = moment(String(this.model.fecha_de)).format("YYYY")+"-"+moment(String(this.model.fecha_de)).format("MM")+"-01";
                var fecha_final = this.model.fecha_de+"T23:59:59.999";

                //EGRESOS MES: FACTURAS DE EGRESOS DONDE LA FECHA DEL UUID RELACIONADO DEL 1 DEL MES AL DIA CONSULTADO  
                var data_otros = {
                  "selector": {
                      "type":"ventas",
                      "fecha_timbrado": {
                          "$gte": fecha_inicial,
                          "$lte": fecha_final
                      },                          
                  },
                  "use_index":"_design/2acea62bb1eacb7d80fc3102cbc18d798f3ab47b",
                  "fields": ["_id",
                      "ticket", "fecha", "total","estatus",
                      "id_facturacion","fecha_timbrado","id_facturacion_egreso","error_timbrado_egreso",  
                      "fecha_cancelado",
                      "estatus_cancelado", //En Proceso|Cancelado                    
                  ]
              };
              await window.axios
                  .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data_otros)
                  .then(async responseOtros => {
                      
                      if (responseOtros.data && responseOtros.data.docs){
                        for(var k in responseOtros.data.docs){
                          var venta = responseOtros.data.docs[k];

                          if(venta["id_facturacion_egreso"] && venta["id_facturacion_egreso"]!=""){
                            
                            if(venta["error_timbrado_egreso"] && venta["error_timbrado_egreso"]!=""){//Si tiene error, mostrarla como sin nota de credito
                              this.facturadas_sin_nota_credito.push(venta);
                            } else {
                              if(!venta["estatus_cancelado"] || (venta["estatus_cancelado"] && venta["estatus_cancelado"]!="Cancelado")){//Si es una factura cancelada, no se toma en cuenta su nota de crédito
                                this.egresos_mes += parseFloat(venta["total"]);                              
                                this.facturadas_mes_detalle.push({"ticket":venta["ticket"], "id_facturacion":venta["id_facturacion_egreso"],
                                  "total":venta["total"], "tipo":"egreso"})
                              }
                            }
                            
                          } 
                          if(venta["estatus"] == "Facturada"){
                            if(!venta["estatus_cancelado"]){
                              this.facturadas_mes += parseFloat(venta["total"]);
                              this.facturadas_mes_detalle.push({"ticket":venta["ticket"], "id_facturacion":venta["id_facturacion"],
                              "total":venta["total"], "tipo":"cliente"})
                            } else if (venta["estatus_cancelado"] && venta["estatus_cancelado"] !="En Proceso"){
                              this.facturadas_mes += parseFloat(venta["total"]);
                              this.facturadas_mes_detalle.push({"ticket":venta["ticket"], "id_facturacion":venta["id_facturacion"],
                              "total":venta["total"], "tipo":"cliente"})
                            }                                                        
                            
                          }                          
                          
                        }
                        
                      }
                  }).catch(error => {
                    console.log(error);
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al consultar facturas de otros días. Intente nuevamente.",
                        footer: ""
                    });
                });

                //DATOS DEL MES Ventas del dia 1ro al dia seleccionado, del mes y año seleccionado                
                let datos_mes = {
                    "selector":{
                      "type":"ventas",
                      "fecha":{"$gte":fecha_inicial,"$lte":fecha_final},
                      "sucursal._id":{"$exists":true},
                      "usuario_id":{"$exists":true},
                      "cliente.nombre":{"$exists":true},
                      "cliente._id":{"$exists":true}
                    },
                    "use_index":"_design/7f056a8e777d8361294cecc9ad40b9a3299b127c",
                    //"sort":[{"fecha":"desc"}],
                    "fields": ["_id", "ticket", "sucursal", "fecha", "cliente","total","estatus",
                      "id_facturacion","fecha_timbrado","id_facturacion_egreso","error_timbrado_egreso",
                      "fecha_cancelado",
                      "estatus_cancelado", //En Proceso|Cancelado
                    ]
                }; 
                
                await window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', datos_mes)
                .then(async responseMes => {                  

                    if (responseMes.data.docs.length > 0){
                        var mensuales = responseMes.data.docs;
                        for(var k in mensuales){
                          var venta = mensuales[k];
                          if(venta["estatus"] == "Finalizada"){
                            this.ventas_sin_facturar.push(venta);
                            this.ventas_totales_mes += parseFloat(venta["total"]);
                          }
                          if(venta["estatus"] == "Facturada"){
                            this.ventas_totales_mes += parseFloat(venta["total"]);                            
                            
                          }                          
                          if(venta["estatus"] == "Facturada" && this.ids_ventas_globales.includes(venta["_id"])){                           
                            if(venta["fecha_timbrado"] && venta["fecha"].substring(0,10) != venta["fecha_timbrado"].substring(0,10)){
                              //Si se facturó otro día debe tener nota de crédito, sino se reporta
                              if(!venta["id_facturacion_egreso"] || (venta["id_facturacion_egreso"] && venta["id_facturacion_egreso"]=="")){
                                this.facturadas_sin_nota_credito.push(venta);                                
                              } 
                            }                          
                          }
                          if(venta["estatus"] == "Factura Global"){   
                            this.ventas_totales_mes += parseFloat(venta["total"]);                            
                            
                            if (venta["id_facturacion_egreso"] && venta["id_facturacion_egreso"]!="" ){
                              if(!venta["estatus_cancelado"] || (venta["estatus_cancelado"] && venta["estatus_cancelado"]!="Cancelado")){//Si es una factura cancelada, no se toma en cuenta su nota de crédito
                                //En la global y con nota de credito. 
                                if(this.ids_ventas_globales.includes(venta["_id"])){
                                  this.global_y_nota_credito.push(venta);
                                }
                              }
                            }

                            if(venta["fecha_timbrado"] && venta["fecha"].substring(0,10) != venta["fecha_timbrado"].substring(0,10)){
                              //Si se facturó otro día debe tener nota de crédito, sino se reporta
                              if(!venta["id_facturacion_egreso"] || (venta["id_facturacion_egreso"] && venta["id_facturacion_egreso"]=="")){
                                //En la global, facturada al cliente y sin nota de credito.
                                if(this.ids_ventas_globales.includes(venta["_id"])){
                                  this.global_y_cliente.push(venta);
                                }
                              }
                            }                          
                          }
                          if(venta["estatus_cancelado"] && venta["estatus_cancelado"]=="En Proceso"){
                              this.facturas_proceso_cancelacion.push(venta);
                          }
                          if(this.ids_ventas_globales.includes(venta["_id"])){
                            this.tickets_globales.push(venta["ticket"]);
                            //Globales por dia, para comprobar mas adelante que pertenezcan al dia de la global
                            if(!this.tickets_globales_dia[venta["fecha"].substring(0,10)]){
                              this.tickets_globales_dia[venta["fecha"].substring(0,10)] = [];                              
                            }
                            this.tickets_globales_dia[venta["fecha"].substring(0,10)].push(venta["ticket"]);
                            
                          }
                        }
                        this.diferencia_mes = this.ventas_totales_mes - this.facturadas_mes + this.egresos_mes;

                    }
                }).catch(error => {
                    console.log(error);
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al consultar detalle del mes. Intente nuevamente.",
                        footer: ""
                    });
                });


                //DUPLICADAS del mes y año seleccionado
                let datos_api = {
                    "rfc": "BGU960207S5A",
                    "mes": this.parse_month(this.model.fecha_de),
                    "anio": this.parse_year(this.model.fecha_de)
                };       

                await axios.post(this.api + 'carta_porte/facturas_duplicadas/', datos_api)                    
                .then(async response => { 
                  //console.log("API: ", response.data);
                  
                  if(response.data && response.data.sin_egreso){
                    var sin_egreso = [];
                    for(var k in response.data.sin_egreso){
                      var fac_egreso = response.data.sin_egreso[k].split("|");
                      //Para obtener las que les falta su egreso en Power, buscamos las de sin egreso en las globales y esas deben tener nota de credito, sino se reportan
                      if(this.tickets_globales.includes(fac_egreso[0])){ 
                        var new_fac = fac_egreso[0]+"|"+fac_egreso[1].toString(); 
                        sin_egreso.push(new_fac);                        
                      }
                      
                    }                    

                    if(sin_egreso.length > 0){

                          await Promise.all(sin_egreso.map(async (val) => {
                            
                            var fac_val = val.split("|");
                            var tick = fac_val[0];
                            var importe = parseFloat(fac_val[1]);

                            let data_egre = {
                                "selector":{
                                  "ticket":tick
                                },
                                "use_index":"_design/1e7fd82e52f4e17082b520be8b8d4fc49ee8ec41",
                                "fields":["_id","ticket","fecha","total","id_facturacion_egreso","id_facturacion","fecha_timbrado"]
                              };

                              await window.axios
                              .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data_egre)
                              .then(response => {                                 
                                if(response.data && response.data.docs){                                  
                                    for(var kr in response.data.docs){
                                      var v = response.data.docs[kr];                                      
                                      //Si el ticket buscado coincide con el importe de la venta encontrada, 
                                      //y está en la global de su día de venta y no tiene egreso, es una facturada sin nota de redito
                                      if (Math.abs(v["total"] - importe) < 10){
                                        if(this.tickets_globales_dia[v["fecha"].substring(0,10)] && this.tickets_globales_dia[v["fecha"].substring(0,10)].includes(v["ticket"])){
                                          if( (!v["id_facturacion_egreso"] || (v["id_facturacion_egreso"] && v["id_facturacion_egreso"]==""))){
                                            //(05-08-2024) Si la fecha de la venta es mas reciente que la fecha de timbrado, entonces no se toma en cuenta
                                            //ya que no se puede facturar una venta antes de su creación (PENDIENTE HACER LA COMPARACION, se necesita la fecha en el arreglo de sin_egresos)

                                            //Buscamos que la venta no se encuentre ya en el arreglo
                                            var find_fac = this.facturadas_sin_nota_credito.find(e => e._id == v["_id"]);
                                            if(find_fac){
                                              console.log("Factura ya agregada a facturadas_sin_nota_credito", v["ticket"]);
                                            } else {
                                              this.facturadas_sin_nota_credito.push(v);
                                            }                                            
                                          }
                                        }                                        
                                      }                                      
                                }
                                }
                              }).catch(error => {
                                  console.log(error);
                                  this.$swal({
                                      type: "error",
                                      title: "¡Operación no Permitida!",
                                      text: "Ocurrió un error al consultar detalle de las ventas duplicadas. Intente nuevamente.",
                                      footer: ""
                                  });
                              });

                          }));
                          
                          
                      } 

                    
                  }
                  if(response.data && response.data.duplicadas){
                      var ticket_duplicados = [];
                      for(var key in response.data.duplicadas){
                        var d = response.data.duplicadas[key];                            
                          for(var kf in d["fecha"]){ //Se supone que deben ser el mismo numero defechas que de uudis
                              var r = {
                                "ticket":d["_id"]["venta"], 
                                "fecha_venta":"",
                                "importe_venta":"",
                                "importe": parseFloat(d["_id"]["total_venta"]), 
                                "fecha":d["fecha"][kf], 
                                "uuid":d["uuid"][kf],
                              };
                              this.facturas_duplicadas_mes.push(r);
                              ticket_duplicados.push(d["_id"]["venta"])
                          }
                          
                      }

                      if(ticket_duplicados.length > 0){

                          await Promise.all(ticket_duplicados.map(async (val) => {

                            let data_duplicadas = {
                                "selector":{
                                  "ticket":val
                                },
                                "use_index":"_design/1e7fd82e52f4e17082b520be8b8d4fc49ee8ec41",
                                "fields":["ticket","fecha","total"]
                              };

                              await window.axios
                              .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data_duplicadas)
                              .then(response => {                                 
                                if(response.data && response.data.docs){
                                  for(var k in this.facturas_duplicadas_mes){
                                    for(var kr in response.data.docs){
                                      if(response.data.docs[kr]["ticket"] == this.facturas_duplicadas_mes[k]["ticket"]){                                            
                                        this.facturas_duplicadas_mes[k]["fecha_venta"] = response.data.docs[kr]["fecha"];
                                        this.facturas_duplicadas_mes[k]["importe_venta"] = response.data.docs[kr]["total"];                                            
                                      }
                                    }
                                  }
                                }
                              }).catch(error => {
                                  console.log(error);
                                  this.$swal({
                                      type: "error",
                                      title: "¡Operación no Permitida!",
                                      text: "Ocurrió un error al consultar detalle de las ventas duplicadas. Intente nuevamente.",
                                      footer: ""
                                  });
                              });

                          }));
                          
                          
                      }                          

                  } 
                }).catch(error => {
                    console.log(error);
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al consultar las ventas duplicadas. Intente nuevamente.",
                        footer: ""
                    });
                });

                window.dialogLoader.hide();  
            }).catch(error => {
                console.log(error);
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al consultar las ventas. Intente nuevamente.",
                    footer: ""
                });
                window.dialogLoader.hide();
            });
    },

    descargaExcel() {

        var self = this;
        window.dialogLoader.show('Espere un momento por favor..');

        let datos = {"id_compra":this.model_vista._id,
                      "fecha": window.moment().format("DD-MM-YYYY HH:mm")};
        window.axios
        .post(process.env.VUE_APP_REPORTES_URL + "/reportes/excel_compra_proveedor/", datos, {            
                    "Content-type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Allow": "POST",
                    "cache-control": "no-cache",
                    "responseType": "arraybuffer"
            })
            .then(response => {
            var bytes = response.data;
            let blob = new Blob([bytes], {
                type: response.headers["content-type"]
                }),
                url = window.URL.createObjectURL(blob);

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = self.get_nombre_corto_proveedor(self.model_vista.proveedor.nombre_proveedor)+"_"+ self.model_vista.no_orden+"_"+ window.moment().format("DD-MM-YYYY")+".xlsx"                
            a.click();
            a.parentNode.removeChild(a);
            })
            .catch(error => {
            console.log(error);
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al generar el excel. Intente nuevamente.",
                footer: ""
            });
            }).then(() => {
                window.dialogLoader.hide();
            });

        },

        formatNumberDec: function(numero, decimales){
            if (numero == undefined || numero == null || numero == "")
                return "0";
            numero = Number(numero);
            if (isNaN(numero)){
                return "0";
            } else
                return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        },
        crear_nota: async function(id_facturacion, id_venta, numero_ticket) {
          
          window.dialogLoader.show('Espere un momento por favor..');

          await this.get_factura_global(id_venta);

          if(this.factura_global == null) {
            window.dialogLoader.hide();

            this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "La venta no se encuentra en una factura global",
                  footer: ""
                });
            
            return;
          }

          var uuid_global = null;
          await this.consultar_factura_global().then(r => {
            uuid_global = r.uuid;
          }).catch(er => {
            uuid_global = null;
          });

          if(uuid_global == null) {
            window.dialogLoader.hide();

            this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "No se encontro el UUID de la factura global",
                  footer: ""
                });
            
            return;
          } 

          this.id_solicitud_egreso = id_facturacion;

          await this.consultar_estatus_egreso().then(r => {
            console.log("then")
          }).catch(er => {
            window.dialogLoader.hide();
            console.log("err: " + er)
            this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al consultar la factura. Por favor intente nuevamente.",
                  footer: ""
                });
            
            return; 
          });

          this.id_solicitud_egreso = null;

          var model_egreso = {
            "rfc": "BGU960207S5A",
            "email": "",
            "json": Object.assign({}, this.model_egreso)
          };

          delete model_egreso.json.Complemento;
          
          var fecha_json = window.moment().format("YYYY-MM-DD HH:mm:ss").replace(' ', 'T');
          fecha_json = fecha_json.toString().substr(0,10) + "T00:00:00";

          model_egreso.json.Fecha = fecha_json;
          model_egreso.json.TipoDeComprobante = "E";
          model_egreso.json.MetodoPago = "PUE";
          model_egreso.json.FormaPago = "01";

          model_egreso.json.CfdiRelacionados = [{"TipoRelacion": "01", "UUIDS": [uuid_global]}]

          model_egreso.json.Receptor = {
            "Rfc": "XAXX010101000",
            "Nombre": "PÚBLICO EN GENERAL",
            "UsoCFDI": "S01",
            "RegimenFiscalReceptor": "616",
            "DomicilioFiscalReceptor": "98600",
            "Domicilio": {
              "Calle": "",
              "CodigoPostal": "",
              "Colonia": "",
              "Estado": "",
              "Localidad": "",
              "Municipio": "",
              "NumeroExterior": "",
              "Pais": "",
              "Referencia": ""
            }
          };
          model_egreso.json.Conceptos = [{
            "Cantidad": "1",
            "ClaveProdServ": "84111506",
            "ClaveUnidad": "ACT",
            "Descripcion": "Nota",
            "Importe": model_egreso.json.SubTotal,
            "ValorUnitario": model_egreso.json.SubTotal,
            "ObjetoImp": "02",
            "NoIdentificacion": numero_ticket,
            "Impuestos": {
              "Traslados": model_egreso.json.Impuestos.Traslados,
            }
          }]

          await axios.post(this.api + 'carta_porte/guardar_mongo/', model_egreso).then(async res => {
            if (typeof res.data !== 'undefined') {
              this.id_solicitud_egreso = res.data.id_solicitud;
              var contador = 0;
              var flag = 0;
              var error_timbrado = "";
              var self = this;
              do {
                await new Promise(r => setTimeout(r, 15000));
                await self.consultar_estatus_egreso().then(r => {
                  flag = r.estatus;
                  error_timbrado = r.error;
                }).catch(er => {
                  flag = er;
                });
                console.log(flag);
                contador += 1;
              } while (flag != 1 && flag != 3 && contador < 11);

              //console.log(id_venta);
              var url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_configuracion/_update/factura_egreso/" + id_venta;
              if (flag == 1) {
                console.log("facturada egreso");
                await window.axios.create({
                    withCredentials: true
                  }).put(url, {
                    "id_facturacion_egreso": self.id_solicitud_egreso,
                    "error_timbrado_egreso": ""
                  }, self.db_config)
                  .then(response => {
                    if (response.data == true) {
                      console.log("updated egreso");
                    } else {
                      console.log(response);
                      console.log("error updated egreso")
                    }
                  })
                  .catch(error => {
                    console.log("error", error);
                  });
                  window.dialogLoader.hide();

              } else {
                await window.axios.create({
                    withCredentials: true
                  }).put(url, {
                    "id_facturacion_egreso": this.id_solicitud_egreso,
                    "error_timbrado_egreso": error_timbrado
                  }, this.db_config)
                  .then(response => {
                    if (response.data == true) {
                      console.log("updated egreso error timbrado");
                      self.$swal({
                        type: "success",
                        title: "¡Operación Exitosa!",
                        text: "Nota creada con exito.",
                        footer: "",
                      });
                    } else {
                      console.log("error timbrado updated egreso")
                      self.$swal({
                        type: "error",
                        title: "¡Advertencia!",
                        text: "Ocurrío un error al actualizar la venta. Cominicalo con el administrador",
                        footer: "",
                      });
                    }
                  })
                  .catch(error => {
                    self.$swal({
                        type: "error",
                        title: "¡Advertencia!",
                        text: "Ocurrío un error al actualizar la venta. Cominicalo con el administrador",
                        footer: "",
                      });
                    console.log("error", error);
                  });
              }
              window.dialogLoader.hide();
              self.consultar();
            }
          }).catch(err => {
            this.$swal({
                        type: "error",
                        title: "¡Advertencia!",
                        text: "Ocurrío un error al actualizar la venta. Cominicalo con el administrador",
                        footer: "",
                      });
            window.dialogLoader.hide();
            console.log(err);
            console.log("error creando egreso");
            this.consultar();
          });

        },
        consultar_estatus_egreso: function() {
          var self = this;
          return new Promise(async function(resolve, reject) {
            await axios.post(self.api + 'carta_porte/solicitud_mongo/', {
                "id_solicitud": self.id_solicitud_egreso
              })
              .then((res) => {
                self.model_egreso = res.data.json;
                if (typeof res.data !== 'undefined') {
                  if (res.data.estatus == 1) {
                    return resolve({
                      "estatus": 1,
                      "error": res.data.error_solicitud
                    });
                  } else if (res.data.estatus > 1) {
                    return resolve({
                      "estatus": 3,
                      "error": res.data.error_solicitud
                    });
                  } else {
                    return resolve({
                      "estatus": 2,
                      "error": res.data.error_solicitud
                    });
                  }
                } else {
                  console.log("en el undefined");
                  return resolve({
                    "estatus": 2,
                    "error": ""
                  });
                }
              })
              .catch((error) => {
                console.log("en el catch", error);
                return reject(3);
              });

          });
        },
        get_factura_global: async function(id) {
          let data = {
            "selector": {
              "type": "facturas_globales",
              "estatus": {"$in": ["Error al Cancelar", "Facturado"]},
              "ids": {"$in": [id]}
            },
          };

          await window.axios.create({withCredentials: true})
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data, this.db_config)
            .then(async response => {
              if (response.data.docs.length > 0) {
                for (var i = 0; i < response.data.docs.length; i++) {
                  if(response.data.docs[i].estatus == "Facturado") {
                    this.factura_global = await response.data.docs[i];
                    break;
                  } else {
                    this.factura_global = null;
                  }
                }
              } else {
                this.factura_global = null;
              }
            })
            .catch(error => {
              this.factura_global = null;
            });
        },
        consultar_factura_global: function() {
          var self = this;

          return new Promise(async function(resolve, reject) {
            await axios.post(self.api + 'carta_porte/solicitud_mongo/', {
                "id_solicitud": self.factura_global.id_facturacion
              })
              .then((res) => {
                if (typeof res.data !== 'undefined') {
                  console.log("encontrada");
                  return resolve({
                    "uuid": res.data.json.Complemento.TimbreFiscalDigital.UUID
                  });
                } else {
                  console.log("en el undefined factura global");
                  return resolve({
                    "uuid": null
                  });
                }
              })
              .catch((error) => {
                console.log("en el catch", error);
                return reject(null);
              });

          });
        },  

  }
}
</script>

<style scoped>

.rojo {
  color: red;
}
#orden_excel>table>tr>td {
    padding-left: 2px;
    padding-right: 2px;
}

#orden_excel>table>tr {
    page-break-inside: avoid
}

</style>